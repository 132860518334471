import { axios } from '@/api'

import { ProcessStatus } from '@/utils/process'
import { OvertaxStorage } from '@/utils/overtax'
import { PageName, SurveyType, Company } from '@/views/SurveyView/libs/useProcess'
import { BusinessType } from '@/constants/questionList';

import { BusinessTypes } from './survey'

export const enum PaymentType {
  PaymentMethodCard = 'PaymentMethodCard',
  PaymentMethodEasyPay = 'PaymentMethodEasyPay',
  PaymentMethodGiftCertificate = 'PaymentMethodGiftCertificate',
  PaymentMethodMobile = 'PaymentMethodMobile',
  PaymentMethodTransfer = 'PaymentMethodTransfer',
  PaymentMethodVirtualAccount = 'PaymentMethodVirtualAccount',
   // below for old version
  CARD = 'CARD',
  ACCOUNT_TRANSFER= 'ACCOUNT_TRANSFER',
  CMS = 'CMS',
}

export interface Refund {
  year: number;
  amount: number;
}

export interface OverTaxUser {
  representative_name: string;
  representative_phone_number: string;
  process: Array<{
    id: number;
    base_year: number;
    status: ProcessStatus;
    refund_amount: Refund[];
    commission_rate: number;
    commission_proposal: number;
    refund_amount_sum: number;
  }>
}

export interface GetUserResponse {
  email: string;
  first_name: string;
  id: number;
  ouid: string;
  last_name: string;
  username: string; // to-be: api에서 제거 되면 삭제 예정
  social_auth: Array<{
    phone_number: string;
    birthyear: string;
    birthday: string;
    name: string;
  }>,
  overtax_user?: null | OverTaxUser;
  alfred_token?: string;
}

interface GetNowResponse {
 date: string;
 hometax: {
  valid: boolean;
  message: string;
 }
}

interface GetCommissionRateResponse {
  commission_rate: number;
}

export interface GetProcessHistoryResponse {
  id: number;
  status: ProcessStatus;
  contract_at: string;
  refunded_at: string;
  commission_rate: number;
  refunded_amount: number;
  bank_name: string;
  bank_account_number: string;
  bank_owner_name: string;
  payment_amount: number;
  is_payment_complete: boolean;
  payment_date: string;
  payment_type: PaymentType;
}

interface ExtraSurveyData {
  biz_types: BusinessTypes[];
  company_name: string;
  registered_at: string;
  representative_name: string;
}

export interface GetUserInformationResponse {
  base_year: string;
  survey_data: SurveyData;
  extra_survey_data: ExtraSurveyData;
  biz_number: string;
  company_name: string;
  survey_type: SurveyType;
  employees: Employee[];
  page_name: PageName;
  is_complete: boolean;
}

export interface GetUserInformationPayload {
  userId: string;
  pageName: PageName;
  surveyType: SurveyType;
  bizNumber?: string;
  token?: string;
}

interface PostUserInformationResponse {
  page_name: PageName;
  is_complete: boolean;
  survey_data?: SurveyData
  review?: string;
}

interface PutUserInformationResponse {
  page_name: PageName;
  is_complete?: boolean;
  survey_data?: SurveyData
  review?: string;
}

export interface PostUserInformationPayload {
  userId: string;
  pageName: PageName;
  surveyType: SurveyType;
  surveyData?: SurveyData;
  isComplete?: boolean;
  bizNumber?: string;
  token?: string;
}

export interface SurveyData {
  employees?: Employee[];
  basic_survey?: string;
  business_types?: BusinessType[]
}

export type selectedProps = BusinessType[] | Employee[] | string

export interface SelectedData {
  no: string,
  text: string;
  selected: selectedProps;
}

export const enum ContractStatus {
  AT_START = 'AT_START', // '입사일 당시'
  UNDER_2_YEARS = 'UNDER_2_YEARS', // '입사 2년 이내',
  OVER_2_YEARS = 'OVER_2_YEARS', // '입사 2년 이후',
  NO = 'NO', //'계약한적 없음',
  UNFOUND = 'UNFOUND' // '잘모름',
}

export const enum FullTimeStatus {
  AT_START = 'AT_START', // '입사일 당시'
  UNDER_1_YEARS = 'UNDER_1_YEARS', // '입사 1년 이내',
  OVER_1_YEARS = 'OVER_1_YEARS', // '입사 1년 이후',
  NO = 'NO', //'계약한적 없음',
  UNFOUND = 'UNFOUND' // '잘모름',
}
export interface Employee {
  emp_reg_number: string;
  name: string;
  birth_date: string;
  work_start_date: string;
  work_end_date: string;
  is_relative: boolean;
  rel_start: string;
  was_relative_not_now: boolean;
  rel_end: string;
  is_regular: boolean;
  regular_promotion_date_survey?: ContractStatus;
  is_fulltime: boolean;
  fulltime_promotion_date_survey?: FullTimeStatus;
  has_employment_contract: boolean;
}

export interface SurveyConfirmProps {
  emp_reg_number: string;
  name: string;
  birth_date: string;
  is_relative: boolean;
  is_regular: boolean;
  is_fulltime: boolean;
}

export interface IsIdCardRequiredResponse {
  required: boolean
}

interface GetNoticeProps {
  title: string;
  content: string;
}

interface getRecommendCodeResponse {
  ref_code: string;
}

export const getUser = (id: string) => {
  return axios.get(`/api/users/${id}/`).then((response): GetUserResponse => response.data);
}

export const login = (code: string) => {
  const sales_id = OvertaxStorage.get('sales_id')
  OvertaxStorage.init('sales_id')
  const utm_source = OvertaxStorage.get('utm_source')
  OvertaxStorage.init('utm_source')
  const utm_medium = OvertaxStorage.get('utm_medium')
  OvertaxStorage.init('utm_medium')
  const ref_code = OvertaxStorage.get('ref_code')
  OvertaxStorage.init('ref_code')
  
  let targetUrl = '/api/social/user/'
  if (OvertaxStorage.get('from') === 'alfred') {
    targetUrl = '/api/social/alfred/user/'
    OvertaxStorage.init('from')
  }
  return axios.post(targetUrl, { provider: 'kakao', code, sales_id }, {
    params: {
      utm_source,
      utm_medium,
      ref_code,
    }, 
  }).then((response): GetUserResponse => response.data)
}

export const logout = () => {
  return axios.post('/api/social/logout/').then((response) => response.data)
}

export const getNow = () => {
  return axios.get('/api/now/').then((response): GetNowResponse => response.data)
}

export const getNotice = () => {
  return axios.get('/api/notice').then((response): GetNoticeProps[] => response.data)
}

export const getCommissionRate = () => {
  return axios.get('/api/common_commission_rate/').then((response):GetCommissionRateResponse => response.data)
}

export const deleteUser = (userId: string, reason: string) => {
  return axios.post(`/api/users/${userId}/delete/`, { reason }).then((response) => response.data)
}

export const getProcessHistory = (userId: string) => {
  return axios.get(`/api/users/${userId}/process_history/`).then((response): GetProcessHistoryResponse[] => response.data)
}

const getInformationUrl = () => {
  const isOperation = OvertaxStorage.get('operator') === true
  return isOperation ? '/api/information/admin' : '/api/information/'
}

const getInformationMainUrl = () => {
  const isOperation = OvertaxStorage.get('operator') === true
  return isOperation ? '/api/information/main/admin' : '/api/information/main/'
}

export const getUserInformationMain = (userId: string, token?: string) => {
  if (token) {
    const searchParams = new URLSearchParams()
    searchParams.append('survey_token', token)
    return axios.get(`${getInformationMainUrl()}?${searchParams.toString()}`).then((response): Company[] => response.data)
  }
  return axios.get(`/api/users/${userId}/information/main/`).then((response): Company[] => response.data)
}

export const getUserInformation = (
  payload: GetUserInformationPayload,
) => {
  const { userId, pageName, surveyType, bizNumber, token } = payload
  const searchParams = new URLSearchParams()
  searchParams.append('survey_type', surveyType)
  searchParams.append('page_name', pageName)
  if (bizNumber) {
    searchParams.append('biz_number', bizNumber)
  }
  if (token) {
    searchParams.append('survey_token', token)
    return axios.get(`${getInformationUrl()}?${searchParams.toString()}`).then((response): GetUserInformationResponse => response.data)
  }
  return axios.get(`/api/users/${userId}/information/?${searchParams.toString()}`).then((response): GetUserInformationResponse => response.data)
}

export const postUserInformation = (
  payload: PostUserInformationPayload,
) => {
  const { userId, surveyType, pageName, bizNumber, isComplete, surveyData, token } = payload
  const searchParams = new URLSearchParams()
  searchParams.append('survey_type', surveyType)
  searchParams.append('page_name', pageName)
  if (bizNumber) {
    searchParams.append('biz_number', bizNumber)
  }
  const data: PostUserInformationResponse = {
    page_name: pageName,
    is_complete: isComplete || false,
    survey_data: surveyData,
  }

  const isOperation = OvertaxStorage.get('operator') === true
  if (isOperation && token) {
    const operationData: PutUserInformationResponse = {
      page_name: pageName,
      survey_data: surveyData,
    }
    searchParams.append('survey_token', token)
    return axios.patch(`/api/information/admin?${searchParams.toString()}`, operationData).then((response): GetUserInformationResponse => response.data)
  }
  if (token) {
    searchParams.append('survey_token', token)
    return axios.post(`/api/information/?${searchParams.toString()}`, data).then((response): GetUserInformationResponse => response.data)
  }
  return axios.post(`/api/users/${userId}/information/?${searchParams.toString()}`, data).then((response): GetUserInformationResponse => response.data)
}

export const getSurveyConfirm = (
  userId: string,
  token?: string,
) => {
  if (token) {
    return axios.get(`/api/information/confirm/?survey_token=${token}`).then((response): SurveyConfirmProps[] => response.data)
  }
  return axios.get(`/api/users/${userId}/information/confirm/`).then((response): SurveyConfirmProps[] => response.data)
}

export const isIdCardRequired = () => {
  return axios.get('/api/information/idcard_required').then((response): IsIdCardRequiredResponse => response.data)
}

export const uploadIDCard = (id: string, front: string, back: string) => {
  const data = {
    identification_card_front: front,
    identification_card_back: back,
  }
  return axios.post(`/api/users/${id}/identification_card/`, data).then((response) => response.data)
}

export const getRecommendCode = () => {
  return axios.get('/api/recommend/').then((response): getRecommendCodeResponse => response.data)
}
